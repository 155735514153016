import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Abbreviation from "../../../../../components/abbreviation"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/reiseformen" />
      }
      navigation={navigation}
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <Heading as="h2" level={2}>
          Lesen & Einprägen
        </Heading>
        <TaskSidebar
          main={
            <PaperStack>
              <Poem size={[3, 3, 4]}>
                <p>Der Nordost wehet,</p>
                <p>Der liebste unter den Winden</p>
                <p>Mir, weil er feurigen Geist</p>
                <p>Und gute Fahrt verheißet den Schiffern.</p>
                <p>Geh aber nun und grüße</p>
                <p>
                  Die schöne{" "}
                  <Abbreviation title="Fluss in Südwest-Frankreich. Fließt in Bordeaux mit der Dordogne zusammen und mündet im Atlantik">
                    Garonne
                  </Abbreviation>
                  ,
                </p>
                <p>
                  Und die Gärten von{" "}
                  <Abbreviation title="Großstadt in Südwest-Frankreich, die insbesondere durch ihren Weinbau bekannt ist">
                    Bourdeaux
                  </Abbreviation>
                </p>
                <p>Dort, wo am scharfen Ufer</p>
                <p>Hingehet der Steg und in den Strom</p>
                <p>Tief fällt der Bach, darüber aber</p>
                <p>Hinschauet ein edel Paar</p>
                <p>
                  Von Eichen und{" "}
                  <Abbreviation title="Weidengewächs">
                    Silberpappeln
                  </Abbreviation>
                  ;
                </p>
                <br />
                <p>
                  <Abbreviation title="mundartlich: Ich kann mich noch gut daran erinnern">
                    Noch denket das mir wohl
                  </Abbreviation>{" "}
                  und wie{" "}
                </p>
                <p>Die breiten Gipfel neiget </p>
                <p>Der Ulmwald, über die Mühl', </p>
                <p>Im Hofe aber wächset ein Feigenbaum. </p>
                <p>An Feiertagen gehn </p>
                <p>Die braunen Frauen daselbst </p>
                <p>Auf seidnen Boden, </p>
                <p>Zur Märzenzeit, </p>
                <p>Wenn gleich ist Nacht und Tag, </p>
                <p>Und über langsamen Stegen, </p>
                <p>Von goldenen Träumen schwer. </p>
                <p>Einwiegende Lüfte ziehen.</p>
                <br />
                <p>Es reiche aber, </p>
                <p>Des dunkeln Lichtes voll, </p>
                <p>Mir einer den duftenden Becher,</p>
                <p>Damit ich ruhen möge; denn süß </p>
                <p>Wär' unter Schatten der Schlummer. </p>
                <p>Nicht ist es gut, </p>
                <p>Seellos von sterblichen </p>
                <p>Gedanken zu seyn. Doch gut </p>
                <p>Ist ein Gespräch und zu sagen </p>
                <p>Des Herzens Meinung, zu hören viel </p>
                <p>Von Tagen der Lieb’, </p>
                <p>Und Thaten, welche geschehen.</p>
                <br />
                <p>
                  Wo aber sind die Freunde?{" "}
                  <Abbreviation title='Figur aus Hölderlins Roman "Hyperion". Brieffreund und enger Vertrauter der Titelfigur'>
                    Bellarmin
                  </Abbreviation>{" "}
                </p>
                <p>Mit dem Gefährten? Mancher</p>
                <p>Trägt Scheue, an die Quelle zu gehn;</p>
                <p>Es beginnet nemlich der Reichtum</p>
                <p>Im Meere. Sie,</p>
                <p>Wie Mahler, bringen zusammen</p>
                <p>Das Schöne der Erd' und verschmähn</p>
                <p>
                  Den{" "}
                  <Abbreviation title="mit Segeln versehenem">
                    geflügelten
                  </Abbreviation>{" "}
                  Krieg nicht, und
                </p>
                <p>Zu wohnen einsam, jahrlang, unter</p>
                <p>Dem entlaubten Mast, wo nicht die Nacht durchglänzen</p>
                <p>Die Feiertage der Stadt,</p>
                <p>Und Saitenspiel und eingeborener Tanz nicht.</p>
                <br />
                <p>
                  Nun aber sind zu{" "}
                  <Abbreviation title="Damit könnte sowohl Indien als auch das von Christoph Kolumbus fälschlicherweise als „Indien“ bezeichnete Amerika gemeint sein">
                    Indiern
                  </Abbreviation>{" "}
                </p>
                <p>Die Männer gegangen, </p>
                <p>Dort an der luftigen Spiz’ </p>
                <p>An Traubenbergen, wo herab</p>
                <p>
                  Die{" "}
                  <Abbreviation title="Fluss in Südwest-Frankreich aus den zusammenfließenden Quellbächen Dore und Dogne">
                    Dordogne
                  </Abbreviation>{" "}
                  kommt.
                </p>
                <p>Und zusammen mit der prächt'gen </p>
                <p>
                  <Abbreviation title="Fluss in Südwest-Frankreich. Fließt in Bordeaux mit der Dordogne zusammen und mündet im Atlantik">
                    Garonne
                  </Abbreviation>{" "}
                  meerbreit{" "}
                </p>
                <p>Ausgehet der Strom. Es nehmet aber</p>
                <p>Und giebt Gedächtniß die See, </p>
                <p>Und die Lieb’ auch heftet fleißig die Augen, </p>
                <p>Was bleibet aber, stiften die Dichter.</p>
              </Poem>
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Lies dir das Gedicht zunächst einmal durch und achte auf Formen
              des Reisens, die darin vorkommen.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
